import Vue from 'vue'
// import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
import { Carousel, CarouselItem, Icon, Image } from 'element-ui'
import App from './App.vue'

Vue.config.productionTip = false
// Vue.use(ElementUI)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Icon)
Vue.use(Image)

new Vue({
  render: h => h(App),
}).$mount('#app')
