<template>
  <div id="app">
    <!-- <div id="topLine">
      <div>
        <i class="el-icon-phone"></i>
        12345678910
      </div>
      <div>
        <i class="el-icon-s-promotion"></i>
        panorobotics@gmail.com
      </div>
    </div> -->
    <div id="topFixed" v-show="scrollY >= 10">
      <img id="topFixedLogo" src="./assets/logo2.png" alt="">
      <div id="topFixedMenu">
          <span class="menuItem">Home</span>
          <span class="menuItem">Services</span>
          <span class="menuItem">Portfolio</span>
          <span class="menuItem">Contact</span>
          <span class="menuItem">About Us</span>
        </div>
    </div>
    <div id="gotoTopButton" v-show="scrollY >= 10" @click="scrollToTop">
      <i class="el-icon-caret-top" style="font-size: 24px"></i>
      <span>Top</span>
    </div>
    <div>
      <el-carousel id="carouselAndTitle" height="800px" direction="vertical" :autoplay="true">
        <el-carousel-item v-for="imgSrc in carouselImages" :key="imgSrc">
          <div style="background-color: #AAAAAA; height: 800px">
            <img :src="imgSrc" alt="" style="object-fit: cover; width: 100%; height: 800px; object-position: center">
          </div>
        </el-carousel-item>
        <img id="Logo" src="./assets/logo2.png" alt="">
        <div id="title">
          <h3 style="margin: 0">Welcome To Our Company!&ensp;<span style="color: #edb509;">——</span></h3>
          <h1 style="font-size: 64px; margin: 0">Excavator guidance system - a good partner for intelligent construction.</h1>
          <p style="margin-top: 50px">The excavator guidance system is your best partner for intelligent construction. It provides you with a cost-effective and convenient construction solution by integrating CAD assisted layout function and high-precision Gps positioning technology. Our system is able to adapt to various complex construction environments and ensure construction accuracy and efficiency, making it an ideal choice for improving your construction quality and efficiency.</p>
        </div>
        <div id="menu">
          <span class="menuItem">Home</span>
          <span class="menuItem">Services</span>
          <span class="menuItem">Portfolio</span>
          <span class="menuItem">Contact</span>
          <span class="menuItem">About Us</span>
        </div>
      </el-carousel>
    </div>
    <div style="margin-top: 50px">
      <div style="text-align: center">      
        <div style="width: 80%; margin: 20px auto; display: flex; justify-content: space-between; align-items: top; flex-wrap: wrap">        
          <div style="width: 50%; text-align: left">
            <div style="font-size: 38px; font-weight: 900;">Reduce Costs <br/> &amp; Increase Efficiency</div>
            <p style="text-indent: 36px; width: 90%">
              Integrated CAD-assisted lofting function, point, line and surface element extraction, no need foiprocess measurement,construction accuracy 2cm.
            </p>
            <p style="text-indent: 36px; width: 90%">
              The fusion technology of Trimble GPS positioning system and Beidou positioning system providesultra-high positioning accuracy and ensures stability under harsh conditions.
            </p>
          </div>
          <img src="./assets/part2/CAD.png" alt="" style="width: 500px">
          <!-- <div class="expertiseCard">
            <div class="expertiseCardUp">
              <img src="./assets/logo.png" alt="">
              <h3>We are the best!</h3>
              <p>We are the best!We are the best!We are the best!We are the best!We are the best!</p>
            </div>
            <div class="expertiseCardDown">
              Read More
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div style="background-color: #d7d7d7; padding-top: 20px; width: 100%; margin: auto">
      <div style="display: flex; justify-content: space-between; align-items: end; flex-wrap: wrap; width: 80%; margin: auto">
        <div style="width: 60%">
          <!-- <p style="font-size: 24px; margin: 10px 0">About Pano CR1&ensp;<span style="color: #edb509; font-weight: 900">——</span></p> -->
          <h1 style="margin: 0">CR1 - The Best Tool for You</h1>
          <p>The CR1 is intended for all types of construction machinery systems. We emphasize building user-friendly and intuitive solutions that maximize the value we provide to dealers and entrepreneurs.</p>
          <div style="display: flex; justify-content: space-between; flex-wrap: wrap">
            <div style="display: flex; gap: 30px; margin: 10px 0">
              <img src="./assets/panoCR1/easy.png" alt="" style="width: 75px; height: 75px">
              <div style="display: flex; flex-direction: column; justify-content: start; width: 250px">
                <h2 style="margin: 0">Simple to Use</h2>
                <p style="margin: 0">The CR1 is quick and easy to set up and easy to use.</p>
              </div>
            </div>
            <div style="display: flex; gap: 30px; margin: 10px 0">
              <img src="./assets/panoCR1/independent.png" alt="" style="width: 75px; height: 75px">
              <div style="display: flex; flex-direction: column; justify-content: start; width: 250px">
                <h2 style="margin: 0">Independent</h2>
                <p style="margin: 0">Minimizing the need for surveyors, operators can see the CAD design, operators can operate independently, and construction quality is assured.</p>
              </div>
            </div>
            <div style="display: flex; gap: 30px; margin: 10px 0">
              <img src="./assets/panoCR1/target.png" alt="" style="width: 75px; height: 75px">
              <div style="display: flex; flex-direction: column; justify-content: start; width: 250px">
                <h2 style="margin: 0">Quality Assurance</h2>
                <p style="margin: 0">Documentation and quality assurance is easily accomplished by transferring precisely measured data between the workstation and the excavator.</p>
              </div>
            </div>
            <div style="display: flex; gap: 30px; margin: 10px 0">
              <img src="./assets/panoCR1/one.png" alt="" style="width: 75px; height: 75px">
              <div style="display: flex; flex-direction: column; justify-content: start; width: 250px">
                <h2 style="margin: 0">No Repeat</h2>
                <p style="margin: 0">Without unnecessary repetition, it's accurate and easy the first time.</p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <img src="./assets/panoCR1/install.png" alt="" style="width: 450px">
        </div>
      </div>
    </div>
    <div id="fakeRoad">
      <div id="fakeRoadEdge">
        <div id="fakeRoadCenterLine"></div>
      </div>
    </div>
    <div style="margin-top: 50px">
      <div style="text-align: center">
        <!-- <div style="font-size: 24px"><span style="color: #edb509">——</span>&ensp;Our Facilities&ensp;<span style="color: #edb509">——</span></div> -->
        <div style="font-size: 48px; font-weight: 900">Application scenarios</div>
      </div>
      <div style="width: 85%; margin: 50px auto; text-align: center">
        <el-carousel :interval="3000" type="card" height="380px">
          <el-carousel-item v-for="item, index in applicationScenarios" :key="index">
              <img :src="item.imgSrc" alt="" style="height: 320px; width: 480px">
              <h3>{{item.title}}</h3>
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div style="width: 85%; margin: 50px auto; display: flex; justify-content: center; flex-wrap: wrap">
        <div class="serviceCard">
          <img src="https://www.revolutionwp.com/wpdemo/land-excavator-pro/wp-content/uploads/sites/29/2024/09/services3.png" alt="">
          <div class="serviceCardRight">
            <h3>PanoRobotics<br/><p style="color: #edb509; font-weight: 900; margin: 0">——</p></h3>          
            <p>We are the best! We are the best! </p>
            <p class="serviceCardLearnMore">LEARN MORE &gt;</p>
          </div>
        </div>
        <div class="serviceCard">
          <img src="https://www.revolutionwp.com/wpdemo/land-excavator-pro/wp-content/uploads/sites/29/2024/09/services3.png" alt="">
          <div class="serviceCardRight">
            <h3>PanoRobotics<br/><p style="color: #edb509; font-weight: 900; margin: 0">——</p></h3>          
            <p>We are the best! We are the best! </p>
            <p class="serviceCardLearnMore">LEARN MORE &gt;</p>
          </div>
        </div>
      </div> -->
    </div>
    <div style="margin-top: 50px; padding: 50px 0; background-image:url('https://www.revolutionwp.com/wpdemo/land-excavator-pro/wp-content/themes/land-excavator-pro/assets/images/testimonials/testimonialsbg.png'">
      <div style="text-align: center">
        <div style="font-size: 24px; color: #efefef"><span style="color: #edb509">——</span>&ensp;Excavator Steps&ensp;<span style="color: #edb509">——</span></div>
        <div style="font-size: 48px; font-weight: 900; color: #efefef">WORKING PROCESS</div>
      </div>
      <div style="width: 80%; margin: auto; height: 200px; border: solid 5px #efefef; color: #efefef">
        直接放流程图吧
      </div>
    </div>
    <div style="margin-top: 50px">
      <div style="text-align: center">
        <div style="font-size: 24px"><span style="color: #edb509">——</span>&ensp;Our Work&ensp;<span style="color: #edb509">——</span></div>
        <div style="font-size: 48px; font-weight: 900">OUR BEST CATEGORIES</div>
      </div>
      <div id="categoriesMenu">
        <p>
          <span v-for="item, index in categoriesMenuItems" :key="index">
            <span :class="['categoriesMenuItem', { 'categoriesMenuItemSelected': catagoriesSelectIndex == index }]" @click="changeCategoriesIndex(index)">{{item}}</span>
            <span class="categoriesMenuSplit" v-show="index != categoriesMenuItems.length - 1">|</span>
          </span>
        </p>
      </div>
      <div id="categoriesContainer">
        <el-image v-for="img, index in catagoriesImageToShow" :key="index" :src="img.imgSrc">
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
      </div>
    </div>
    <div style="margin-top: 50px; padding: 50px 0; background-image:url('https://www.revolutionwp.com/wpdemo/land-excavator-pro/wp-content/themes/land-excavator-pro/assets/images/testimonials/testimonialsbg.png'">
      <div style="text-align: center">
        <div style="font-size: 24px; color: #efefef"><span style="color: #edb509">——</span>&ensp;Excavator Choose&ensp;<span style="color: #edb509">——</span></div>
        <div style="font-size: 48px; font-weight: 900; color: #efefef">WHY CHOOSE US</div>
      </div>
      <div style="width: 80%; margin: 50px auto; display: flex; flex-wrap: wrap; justify-content: space-around">
        <div class="whyChooseUsCard" v-for="item, index in whyChooseUsCard" :key="index">
          <img :src="item.imgSrc" alt="">
          <!-- <el-image :src="item.imgSrc">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image> -->
          <div>
            <h3 style="color: #efefef">{{item.title}}</h3>
            <p style="color: grey">{{item.desc}}</p>
          </div>
        </div>
      </div>
    </div>
    <div id="footer">
      <p>Copyright @ 2024 ArchMasters Architecture | Powered by ArchMasters Architecture</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      scrollY: 0,
      carouselImages: [
        require('@/assets/cover/1.jpg'),
        require('@/assets/cover/2.jpg'),
        require('@/assets/cover/3.jpg')
      ],
      applicationScenarios: [
        {
          imgSrc: require('@/assets/scenarios/scene1.png'),
          title: 'Water Conservancy Projects'
        },
        {
          imgSrc: require('@/assets/scenarios/scene2.jpg'),
          title: 'Road Construction'
        },
        {
          imgSrc: require('@/assets/scenarios/scene3.jpg'),
          title: 'CAD import'
        },
        {
          imgSrc: require('@/assets/scenarios/scene4.png'),
          title: 'Earthwork'
        },
      ],
      catagoriesSelectIndex: 0,
      categoriesMenuItems: ['ALL', 'Excavators', 'Bulldozer'],
      categoriesImg: [
        {
          category: 'Excavators',
          imgSrc: 'https://www.revolutionwp.com/wpdemo/land-excavator-pro/wp-content/uploads/sites/29/2024/09/projects6.png'
        },
        {
          category: 'Excavators',
          imgSrc: 'https://www.revolutionwp.com/wpdemo/land-excavator-pro/wp-content/uploads/sites/29/2024/09/projects6.png'
        },
        {
          category: 'Excavators',
          imgSrc: 'https://www.revolutionwp.com/wpdemo/land-excavator-pro/wp-content/uploads/sites/29/2024/09/projects6.png'
        },
        {
          category: 'Bulldozer',
          imgSrc: 'https://www.revolutionwp.com/wpdemo/land-excavator-pro/wp-content/uploads/sites/29/2024/09/projects5.png'
        },
        {
          category: 'Bulldozer',
          imgSrc: 'https://www.revolutionwp.com/wpdemo/land-excavator-pro/wp-content/uploads/sites/29/2024/09/projects5.png'
        },
        {
          category: 'Bulldozer',
          imgSrc: 'https://www.revolutionwp.com/wpdemo/land-excavator-pro/wp-content/uploads/sites/29/2024/09/projects5.png'
        }
      ],
      whyChooseUsCard: [
        {
          imgSrc: require('@/assets/logo.png'),
          title: 'OVER 30 YEARS OF EXPERIENCE',
          desc: 'We are the best! We are the best! We are the best! We are the best! We are the best! We are the best! We are the best! '
        },
        {
          imgSrc: require('@/assets/logo.png'),
          title: 'OVER 30 YEARS OF EXPERIENCE',
          desc: 'We are the best! We are the best! We are the best! We are the best! We are the best! We are the best! We are the best! '
        },
        {
          imgSrc: require('@/assets/logo.png'),
          title: 'OVER 30 YEARS OF EXPERIENCE',
          desc: 'We are the best! We are the best! We are the best! We are the best! We are the best! We are the best! We are the best! '
        },
        {
          imgSrc: require('@/assets/logo.png'),
          title: 'OVER 30 YEARS OF EXPERIENCE',
          desc: 'We are the best! We are the best! We are the best! We are the best! We are the best! We are the best! We are the best! '
        }
      ]
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    changeCategoriesIndex(index) {
      this.catagoriesSelectIndex = index
    },
    handleScroll() {
      this.scrollY = window.scrollY
    },
    scrollToTop() {
      // 平滑滚动回到顶部
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },
  computed: {
    catagoriesImageToShow() {
      return this.categoriesImg.filter(i => {
        if (this.catagoriesSelectIndex === 0) {
          return true
        } else {
          return this.categoriesMenuItems[this.catagoriesSelectIndex] === i.category
        }
      })
    }
  }
}
</script>

<style>
  #topLine {
    background-color: #edb509;
    padding: 5px 15%;
    color: whitesmoke;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  #carouselAndTitle {
    position: relative;
  }
  #title {
    position: absolute;
    color: whitesmoke;
    z-index: 100;
    width: 60%;
    left: 15%;
    top: 30%;
  } 
  #Logo {
    position: absolute;
    z-index: 100;
    width: 300px;
    background-color: white;
    padding: 10px;
    left: 15%;
    top: 5%;
  }
  #topFixed {
    position: fixed;
    z-index: 130;
    padding: 10px 15%;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #050036;
  }
  #gotoTopButton {
    position: fixed;
    z-index: 130;
    right: 20px;
    top: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: #edb509;
    color: white;
    font-weight: 900;
    padding: 10px;
    transition: height 0.5s ease;
  }
  #gotoTopButton:hover {
    cursor: pointer;
    height: 75px;
  }
  #topFixedMenu {
    color: rgba(245, 245, 245, 0.7);
  }
  #topFixedLogo {
    width: 140px;
    background-color: white;
    padding: 10px;
  }
  #menu {
    position: absolute;
    color: rgba(245, 245, 245, 0.7);
    z-index: 120;
    width: 600px;
    right: 15%;
    padding: 10px;
    top: 5%;
    display: flex;
    justify-content: end;
  }
  .menuItem {
    margin: 0 20px;
  }
  .menuItem:hover {
    color: #edb509;
    cursor: pointer;
  }
  .expertiseCard {
    width: 300px;
    height: 250px;
    padding: 20px 20px 40px 20px;
    margin: 30px 0;
    border-style: dashed;
    border-color: #d7d7d7;
    border-width: 3px;
  }
  .expertiseCardUp {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  .expertiseCardUp img {
    width: 125px;
    height: 125px;
  }
  .expertiseCardUp h3 {
    margin: 0;
  }
  .expertiseCardDown {
    margin: auto;
    width: 30%;
    padding: 10px 20px;
    border: 3px solid #d7d7d7;
    border-radius: 30px;
    background-color: #d7d7d7;
    font-weight: 900;
    transition: width 0.5s ease;
  }
  .expertiseCardDown:hover {
    width: 70%;
    background-color: #edb509;
    color: whitesmoke;
    cursor: pointer;
  }
  #fakeRoad {
    background-color: #1a1a1a;
    height: 60px;
    display: flex;
    align-items: center;
  }
  #fakeRoadEdge {
    width: 100%;
    height: 45px;
    border-style: solid none solid none;
    border-color: #edb509;
    border-width: 3px;
    display: flex;
    align-items: center;
  }
  #fakeRoadCenterLine {
    width: 100%;
    height: 0px;
    border-top-style: dashed;
    border-color: #d7d7d7;
  }
  .serviceCard {
    margin: 10px;
    display: flex;
    gap: 0;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.2);
  }
  .serviceCardRight {
    width: 200px;
    padding: 10px;
    position: relative;
  }
  .serviceCardLearnMore {
    color: #edb509; 
    font-weight: 900;
    position: absolute;
    bottom: 20px;
  }
  .serviceCardLearnMore:hover {
    cursor: pointer;
    color: #e6c668;
  }
  #categoriesMenu {
    text-align: center;
  }
  .categoriesMenuSplit {
    color: #edb509;
    font-weight: 900;
    margin: 0 20px
  }
  .categoriesMenuItem:not(.categoriesMenuItemSelected) {
    padding: 10px 20px;
    color: grey;
  }
  .categoriesMenuItemSelected {
    padding: 10px 20px;
    color: #ffffff;
    cursor: pointer;
    font-weight: 900;
    background-color: #edb509;
    border-radius: 50px;
  }
  .categoriesMenuItem:hover {
    color: #ffffff;
    cursor: pointer;
    font-weight: 900;
    background-color: #edb509;
    border-radius: 50px;
  }
  #categoriesContainer {
    margin: auto; 
    width: 80%; 
    display: flex; 
    flex-wrap: wrap; 
    justify-content: space-around;
  }
  #categoriesContainer img {
    margin: 20px 0 0 0;
  }
  .whyChooseUsCard {
    width: 500px;
    height: 150px;
    margin-top: 30px;
    padding: 10px;
    border: dashed 3px #efefef;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .whyChooseUsCard img {
    width: 100px;
    height: 100px;
    margin: 20px;
  }
  #footer {
    width: 100%;
    background-color: #050036;
    color: white;
    text-align: center;
    margin: 0;
    /* padding: 1px 0; */
  }
  #footer p {
    margin: 0;
    padding: 5px 0;
  }
</style>
